<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="banner">
        <img src="../../assets/images/pinpai/life-brand.jpg" alt="">
      </div>

      <div class="wrap wrap1">
        <div class="page">
          <img src="../../assets/images/pinpai/why.png" alt="" class="why">
          <div class="description">
            小院品牌主创团队在深入体验伦敦居民生活形态后，为当地人们的生活状态所打动，于是开始从中国传统的居住形式——合院着手，结合中国传统的庭院生活理念，寻求未来人居的解决方案。历经5年的潜心钻研，小院团队在联合英国BRE机构、卡迪夫大学、并集结一群有相同志趣的伙伴，攻克重重技术壁垒之后，决定给中国老百姓提供一种90-140㎡的绿色低层庭院式住宅产品，由此，小院生活品牌应运而生。
          </div>
          <img src="../../assets/images/pinpai/whel.png" alt="" class="whel">
        </div>

      </div>

      <div class="wrap wrap2">
        <div class="page">
          <div class="img-wrap">
            <img src="../../assets/images/pinpai/goal.png" alt="" class="goal">
            <img src="../../assets/images/pinpai/wmdmb.png" alt="" class="wmdmb">
          </div>

          <div class="character-wrap">
            <div class="title">
              创领城市未来
            </div>
            <div class="description">
              小院生活始于2015年，依托于西安小院科技股份有限公司、西安小院生活馆商业运营管理有限公司，为探索未来美好人居生活而创立的生活服务型品牌。低碳、智能、舒适、绿色已成为未来城市人居的发展方向，小院认可并将引领这样的趋势，希冀通过订制化的庭院式住宅产品以及全周期配套服务，为用户构建高品质的生活空间，创造美好的生活方式，提供超越期待的邻里服务体验。我们相信，通过小院生活对未来城市的不断探索和研究，将在极大程度上解决现有城市问题，为城市人居环境的可持续发展做出贡献。
            </div>
          </div>
        </div>

      </div>

      <div class="wrap wrap3">

        <div class="page">
          <div class="character-wrap">
            <div class="title">
              西安小院科技股份有限公司简介
            </div>
            <div class="description">
              小院科技致力于庭院式住宅产品的研发与推广，为用户提供包括试住、订制、购房、家装、智慧生活解决方案等一站式生活配套服务。公司自成立以来即专注于未来人居环境的改善和生活方式的变革，经过不断的实践与探索，以领先于国际的水平，推出了低碳节能、安全环保，且低于市场价30%的低层庭院式住宅产品，形成了以小院生活馆、社区医疗、家居个性化定制，以及全周期服务等为中心的小院生活+全周期配套商业平台，开启城市新未来！在新的历史发展阶段，公司将以国家政策为指引，服务中心、围绕大局，将企业打造成为城市更新的重要运作平台之一，积极、高效地推动城市更新与创新，助力新型智慧城市的规划与建设，为城市空间布局与土地利用提供新思路，为城市功能完善和社会发展做出更大贡献。
            </div>
          </div>
          <div class="icon-list-wrap">
            <div class="icon-wrap">
              <div class="iconfont icon-ditanjieneng"></div>
              <div class="title">低碳节能</div>
            </div>
            <div class="icon-wrap">
              <div class="iconfont icon-jiahao"></div>
            </div>
            <div class="icon-wrap">
              <div class="iconfont icon-anquanhuanbao"></div>
              <div class="title">安全环保</div>
            </div>
            <div class="icon-wrap">
              <div class="iconfont icon-jiahao"></div>
            </div>
            <div class="icon-wrap">
              <div class="iconfont icon-tingyuanshizhuzhai"></div>
              <div class="title">庭院式住宅</div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap wrap4">
        <div class="page">
          <div class="character-wrap">
            <div class="title">
              西安小院生活馆
            </div>
            <div class="title">
              商业运营管理有限公司简介
            </div>
            <div class="description">
              西安小院生活馆是西北首家以倡导健康生活方式、提高居民生活品质为宗旨的社区服务连锁型企业。公司秉承“与社区居民做朋友”的理念，以专业化、市场化以及精细化的运营管理，通过“社区馆+旗舰馆+MINI馆”全城市覆盖的模式，为居民提供包括社区消费、社区文化活动、社区物业等全周期的社区生活服务，内容涵盖咖啡、饮品、健身、简餐等，并特设阅读区，为会员提供海量图书免费借阅和新书订阅的服务。每家生活馆都是我们为企业与消费者沟通、交流、服务提供的第三生活空间。
            </div>
          </div>
          <div class="lifeList">
            <div class="item">
              <img src="../../assets/images/index10.jpg" alt="">
              <div class="warp">
                <img src="../../assets/images/index10-1.png" alt="">
                <div>咖啡</div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/index11.jpg" alt="">
              <div class="warp">
                <img src="../../assets/images/index11-1.png" alt="">
                <div>小院图书馆</div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/index12.jpg" alt="">
              <div class="warp">
                <img src="../../assets/images/index12-1.png" alt="">
                <div>健身馆</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'

export default {
  data () {
    return {

    }
  },
  components: {
    Header2022,
    Footer2022,
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding-top: 64px;
  .banner > img {
    width: 100%;
  }
  .wrap1 {
    background-image: url('../../assets/images/pinpai/origin.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .page {
      display: flex;
      flex-direction: column;
      .why {
        width: 552px;
        margin: 60px auto 40px;
      }
      .description {
        width: 680px;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
        margin: 0 auto 60px;
        text-align: justify;
        font-weight: 400;
      }
      .whel {
        width: 100%;
      }
    }
  }
  .wrap2 {
    background-image: url('../../assets/images/pinpai/since.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .page {
      display: flex;
      .img-wrap {
        display: flex;
        flex-direction: column;
        .goal {
          width: 428px;
          align-self: flex-end;
          margin-top: 120px;
          margin-right: 100px;
        }
        .wmdmb {
          width: 752px;
          align-self: flex-start;
          margin-top: 70px;
        }
      }
      .character-wrap {
        margin-top: 146px;
        width: 372px;
        .title {
          font-size: 24px;
          font-weight: 900;
          color: #303233;
          line-height: 24px;
        }
        .description {
          font-size: 16px;
          color: #333333;
          line-height: 30px;
          margin-top: 32px;
          text-align: justify;
          font-weight: 400;
        }
      }
    }
  }
  .wrap3 {
    background-image: url('../../assets/images/pinpai/gsjj.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 130px 0 120px;
    color: #fff;
    .page {
      text-align: center;
      .character-wrap {
        .title {
          font-size: 40px;
          font-weight: bold;
          line-height: 40px;
        }
        .description {
          width: 680px;
          font-size: 16px;
          line-height: 30px;
          margin: 65px auto 0;
          text-align: justify;
        }
      }
      .icon-list-wrap {
        display: flex;
        width: 812px;
        margin: auto;
        justify-content: space-between;
        margin-top: 76px;
        .icon-wrap {
          .iconfont {
            font-size: 74px;
            &.icon-jiahao {
              font-size: 46px;
              margin-top: 14px;
            }
          }
          .title {
            font-size: 24px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .wrap4 {
    .page {
      padding: 146px 0 164px;
      .character-wrap{
        text-align: center;
        .title{
          font-size: 40px;
          line-height: 52px;
          font-weight: 900;
          color: #2C4445;
        }
        .description{
          width: 904px;
          margin: auto;
          font-size: 16px;
          line-height: 30px;
          color: #333333;
          text-align: justify;
          margin-top: 64px;
        }
      }
      .lifeList {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .item {
          width: 350px;
          position: relative;
          img {
            width: 100%;
          }
          .warp {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 24px;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.5);
            img {
              width: 70px;
              margin-bottom: 35px;
            }
          }
        }
      }
    }
  }
}
</style>